import React from "react"
import styled, { keyframes } from "styled-components"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import Img from "gatsby-image"

class IntroHeader extends React.PureComponent {
  componentDidMount() {}

  render() {
    return (
      <Container>
        <AliceCarousel
          autoPlay
          dotsDisabled
          fadeOutAnimation
          stopAutoPlayOnHover={false}
          autoPlayInterval={5000}
          duration={1500}
          showSlideInfo={false}
          buttonsDisabled
        >
          {//Loop over slides from ACF. first slide is different from the rest
          this.props.slides.map((slide, index) =>
            index === 0 ? (
              <Slide key={index}>
                {/* <SlideImg
              onLoad={() => console.log("Loaded")}
              onLoadedData={() => console.log("Loaded")}
              src={img1}
              style={{ position: "absolute", top: 0, left: 0 }}
            /> */}
                <Img
                  style={{
                    width: "100%",
                    height: "100%",
                    margin: 0,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    objectFit: "cover",
                  }}
                  fadeIn
                  objectFit="cover"
                  fluid={slide.localFile.childImageSharp.fluid}
                />
                <Logo src={require("../images/logo_white.png")} />
              </Slide>
            ) : (
              <Slide key={index}>
                <Img
                  style={{
                    width: "100%",
                    height: "100%",
                    margin: 0,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    objectFit: "cover",
                  }}
                  fadeIn
                  objectFit="cover"
                  fluid={slide.localFile.childImageSharp.fluid}
                />
                <SlideCopy>{this.props.copies[index]}</SlideCopy>
              </Slide>
            )
          )}
        </AliceCarousel>
      </Container>
    )
  }
}

export default IntroHeader

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const Container = styled.div`
  width: 100%;
  /* height: 60vh; */
  background: white;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  /* padding-top: 60px; */

  @media (min-width: 650px) {
    padding-top: 0;
  }

  div > div > div > ul > li {
    margin-bottom: 0;
  }
`
const Logo = styled.img`
  margin: 0;
  opacity: 0;
  z-index: 4;
  align-self: center;
  justify-self: center;
  width: 80%;
  max-width: 500px;
  max-width: ${props => props.small && "40px"};
  animation: ${fadeIn} 0.7s ease-in 0.1s forwards;
`
const Slide = styled.div`
  width: 100%;
  overflow: hidden;
  height: 40vh;
  min-height: 40vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 800px) {
    height: 60vh;
    min-height: 60vh;
  }
`
const SlideImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`
const SlideCopy = styled.h1`
  font-family: "Articulat";
  font-weight: 600;
  font-size: 26px;
  color: white;
  z-index: 2;
  /* padding-left: 10%; */
  /* padding-right: 30%; */
  padding: 0 15%;
  text-shadow: 0 2px 7px rgba(0, 0, 0, 0.32);
  width: 100%;
  animation: ${fadeIn} 0.7s ease-in 0.1s forwards;
  justify-self: center;
  text-align: center;

  @media (min-width: 550px) {
    font-size: 38px;
  }

  @media (min-width: 750px) {
    font-size: 46px;
  }
`
